<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title> Dodaj płatność </v-card-title>

        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-form ref="form" v-model="valid" lazy="true">
              <div>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-icon>{{ icons.mdiCashMultiple }}</v-icon>
                  </v-col>
                  <v-col>
                    <h3 class="grey--text text--darken-2">Płatność</h3>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col>
                    Wybierz użytkownika. Na tej podstawie automatycznie dobierzemy transporty o
                    statusie "dostarczone" oraz wyliczymy kwotę, która powinna być opłacona przez
                    klienta.
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="user"
                      :items="items"
                      :loading="isLoading"
                      :search-input.sync="search"
                      color="black"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="API"
                      label="Użytkownik"
                      placeholder="Wpisz dane użytkownika"
                      prepend-icon="mdi-database-search"
                      return-object
                      @click="findUser"
                    ></v-autocomplete>
                  </v-col>

                  <v-col v-if="!message" cols="12">
                    <v-combobox
                      v-model="order"
                      :items="o_items"
                      :loading="o_isLoading"
                      :search-input.sync="o_search"
                      color="black"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      label="Transport"
                      placeholder="Wpisz numer transportu"
                      prepend-icon="mdi-database-search"
                      return-object
                      :chips="true"
                      :deletable-chips="true"
                      :multiple="true"
                      :hint="
                        !isEditingTransport
                          ? 'Kilkinj ikonę po prawej by edytować'
                          : 'Kliknij ikone po prawej by zapisać'
                      "
                      :readonly="!isEditingTransport"
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-icon
                            :key="`icon-${isEditingTransport}`"
                            :color="isEditingTransport ? 'success' : 'info'"
                            @click="isEditingTransport = !isEditingTransport"
                            v-text="
                              isEditingTransport ? 'mdi-check-outline' : 'mdi-circle-edit-outline'
                            "
                          ></v-icon>
                        </v-slide-x-reverse-transition>
                      </template>

                      <template v-slot:item="{ item }">
                        <div class="fullsize px-3" @click.prevent.stop="addToList(item)">
                          {{ item.t_number }}
                        </div>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col v-if="transports.length" cols="12">
                    <v-expansion-panels multiple v-model="panels">
                      <v-expansion-panel>
                        <v-expansion-panel-header> Lista transportów </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table :headers="tableHeaders" :items="transports">
                            <template v-slot:[`item.priceVat`]="{ item }">
                              {{
                                (
                                  parseFloat(item.priceVat) +
                                  parseFloat(item.pickup_price ? item.pickup_price : 0)
                                ).toFixed(2)
                              }}
                              zł
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                              <a target="_blank" :href="'/transport/' + item.id"> Zobacz </a>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>

                  <v-col v-if="!message" cols="12">
                    <v-text-field
                      v-model="price"
                      :rules="rulesCM"
                      label="Kwota do zapłaty (brutto)"
                      name="price"
                      type="number"
                      prepend-icon="mdi-cash-multiple"
                      :hint="
                        !isEditingPrice
                          ? 'Kilkinj ikonę po prawej by edytować'
                          : 'Kliknij ikone po prawej by zapisać'
                      "
                      :readonly="!isEditingPrice"
                      min="0"
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <!-- <v-icon
                              :key="`icon-${isEditingPrice}`"
                              :color="isEditingPrice ? 'success' : 'info'"
                              @click="isEditingPrice = !isEditingPrice"
                              v-text="isEditingPrice ? 'mdi-check-outline' : 'mdi-circle-edit-outline'"
                            ></v-icon> -->
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- uncomment in the future
                    <v-col cols="12">
                      <v-select
                        :items="['tak', 'nie']"
                        label="Czy wygenerować nową fakturę?"
                        v-model="invoice"
                        prepend-icon="mdi-paperclip"
                        :rules="rulesReq"
                      ></v-select>
                    </v-col>
-->
                  <v-col v-if="!message" cols="12">
                    <h3 class="mb-2">Termin płatności:</h3>
                    <div class="text-center">
                      <v-date-picker
                        v-model="date"
                        color="amber darken-1"
                        :first-day-of-week="1"
                        locale="pl-PL"
                      ></v-date-picker>
                    </div>
                  </v-col>

                  <v-col v-if="message" cols="12">
                    <v-alert type="error">
                      {{ message }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="!message">
                  <v-spacer />
                  <v-col cols="12" sm="3">
                    <v-btn
                      block
                      @click="addPayment()"
                      :disabled="!valid || !user.id || preventSpaming"
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      :x-large="true"
                      v-tilt
                      >Dodaj płatność</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import axios from 'axios';

  import { mdiCashMultiple } from '@mdi/js';

  export default {
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiCashMultiple,
      },
      entries: [],
      o_entries: [],
      isLoading: false,
      isEditingTransport: false,
      isEditingPrice: false,
      search: null,
      user: {},
      o_isLoading: false,
      o_search: null,
      order: [],
      price: null,
      invoice: 'nie', //temporary set to "nie". User can change after Streamsfot integration
      date: new Date().toISOString().substr(0, 10),
      valid: true,
      preventSpaming: false,
      rulesCM: [
        v => !!v || 'Pole jest wymagane',
        v => /[0-9]/.test(v) || 'Wprowadzona wartość jest niepoprawna',
      ],
      rulesReq: [v => !!v || 'Pole jest wymagane'],
      message: null,
      transports: [],
      tableHeaders: [
        {
          text: 'Numer transportu',
          align: 'left',
          value: 't_number',
        },
        {
          text: 'Miejsce dostawy',
          value: 'destination',
        },
        {
          text: 'Kwota brutto',
          value: 'priceVat',
          sortable: false,
        },
        {
          text: 'Szczegóły',
          value: 'id',
          sortable: false,
        },
      ],
      panels: [],
    }),
    computed: {
      sum() {
        let price = 0;
        this.transports.forEach(elem => {
          price += parseFloat(elem.priceVat) + parseFloat(elem.pickup_price ? elem.pickup_price : 0);
        });
        return price.toFixed(2);
      },
      fields() {
        if (!this.model) return [];

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          };
        });
      },
      items() {
        return this.entries.map(entry => {
          const Description = `${entry.name} ${entry.surname} (${entry.email})`;
          return Object.assign({}, entry, { Description });
        });
      },
      o_items() {
        return this.o_entries.map(entry => {
          const Description = `${entry.t_number}`;
          return Object.assign({}, entry, { Description });
        });
      },
    },
    watch: {
      search() {
        // Items have already been loaded
        if (this.items.length > 0) return;

        // Items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        axios({
          url: this.$store.state.url + '/api/users/get/customers',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            this.count = resp.data.users.length;
            this.entries = resp.data.users;
          })
          .catch()
          .finally(() => (this.isLoading = false));
      },
      o_search(val) {
        if (val) {
          // Items have already been loaded
          //if (this.o_items.length > 0) return

          // Items have already been requested
          if (this.o_isLoading) return;

          this.o_isLoading = true;

          axios({
            url: this.$store.state.url + '/api/transport/get',
            data: {
              token: this.$store.state.token,
              type: 'all',
              params: {
                t_number: val,
              },
            },
            method: 'POST',
          })
            .then(resp => {
              this.o_count = resp.data.transports.length;

              if (resp.data.transports.length) {
                this.o_entries = [{ t_number: resp.data.transports[0].t_number }];
              } else {
                this.o_entries = [];
              }
            })
            .catch()
            .finally(() => (this.o_isLoading = false));
        } else {
          return '';
        }
      },
      user: function () {
        this.getBilling();
      },
      order: function (val) {
        if (val) {
          this.getTransports(val);
        }
      },
    },
    methods: {
      addToList: function (item) {
        this.order.push(item.t_number);
        this.o_search = null;
        this.o_entries = [];
      },
      findUser: function () {
        if (this.search === null) this.search = '';
      },
      addPayment: function () {
        this.preventSpaming = true;
        axios({
          url: this.$store.state.url + '/api/payment/create',
          data: {
            token: this.$store.state.token,
            user_id: this.user.id,
            price: parseFloat(this.price),
            date_expected: this.date,
            // date_payed: this.date,
            orders: this.order,
            payed: this.invoice == 'tak' ? true : false,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.status == 200) {
              this.$router.push({
                name: 'paymentsSingle',
                params: {
                  id: resp.data.payment_id,
                },
              });
            } else {
              alert('Błąd');
              this.preventSpaming = false;
            }
          })
          .catch(() => {
            this.preventSpaming = false;
          });
      },
      getBilling: function () {
        this.message = null;
        axios({
          url: this.$store.state.url + '/api/payment/get-billing',
          data: {
            token: this.$store.state.token,
            user_id: this.user.id,
          },
          method: 'POST',
        })
          .then(res => {
            //put here correct redirection after payment adding
            if (!res.data.length) {
              this.transports = [];
              this.message =
                'Brak możliwości wprowadzenia płatności dla tego użytkownika. Sprawdź, czy wszystkie transporty, które chcesz rozliczyć mają status "dostarczone".';
            } else {
              this.order = [];
              res.data.forEach(order => {
                this.order.push(order.t_number);
              });
            }
          })
          .catch();
      },
      getTransports: function (transports) {
        let search = {};

        if (transports && transports.length) {
          search.t_number = [];
          transports.forEach(t => {
            search.t_number.push(t);
          });
        } else {
          search.t_number = [null];
        }

        axios({
          url: this.$store.state.url + '/api/transport/search',
          data: {
            token: this.$store.state.token,
            search: search,
            nolimit: true,
          },
          method: 'POST',
        }).then(resp => {
          if (resp.data.status === 200) {
            this.transports = [];
            resp.data.transports.forEach(transport => {
              this.transports.push({
                id: transport.transport_id,
                t_number: transport.t_number,
                destination: `${transport.r_postcode} ${transport.r_city}`,
                pickup: `${transport.s_postcode} ${transport.s_city}`,
                price: transport.price,
                priceVat: transport.priceVat,
                pickup_price: transport.pickup_price,
              });
            });
          }

          this.price = this.sum;
        });
      },
    },
  };
</script>
<style scoped>
  .v-list-item__title {
    font-size: 1.5rem;
  }
  .bottom-spacer {
    padding-bottom: 6rem;
  }
  .v-chip--disabled {
    color: rgba(0, 0, 0, 1);
  }
  .v-chip--disabled {
    color: rgba(0, 0, 0, 1);
  }
  .fullsize {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
  }
</style>
